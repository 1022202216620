@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src:
    local('Kanit'),
    local('Kanit-Regular'),
    url(./styles/kanit.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src:
    local('Kanit'),
    local('Kanit-Regular'),
    url(./styles/kanit-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url(./styles/Roboto-Regular.ttf) format('truetype');
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url(./styles/Roboto-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

html * {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.img-nav {
  height: 100%;
}
