.m-calendar {
  display: inline-block;
}
.m-calendar table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
.m-calendar td {
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
  color: #dfe0e4;
  font-size: 16px;
  border: 1px solid #dfe0e4;
}
.m-calendar thead td {
  color: #aa6326;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
}
.m-calendar tbody td {
  color: #666666;
}
.m-calendar tbody td:hover {
  background: #666666;
  border-color: #666666;
  color: #ffffff;
}
.m-calendar .current-day {
  background: #aa6326;
  border-color: #aa6326;
  color: #ffffff;
}
.m-calendar .prev-month,
.m-calendar .next-month {
  color: #999999;
}
.m-calendar .toolbar {
  line-height: 30px;
  color: #aa6326;
  text-align: center;
  margin-bottom: 13px;
}
.m-calendar .toolbar button {
  position: relative;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  border: 1px solid #aa6326;
  border-radius: 50%;
  background: #aa6326;
  font-size: 20px;
  padding: 0;
  text-align: center;
  outline: 0;
  z-index: 5;
  cursor: pointer;
}
.m-calendar .toolbar .prev-month {
  float: left;
}
.m-calendar .toolbar .next-month {
  float: right;
}
.m-calendar .toolbar .current-date {
  color: #aa6326;
  font-size: 18px;
}
.m-time {
  color: #ffffff;
  padding-top: 50px;
}
.m-time .showtime {
  text-align: center;
}
.m-time .separater {
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
  color: #aa6326;
  width: 32px;
  height: 65px;
  line-height: 65px;
  text-align: center;
}
.m-time .time-text {
  position: relative;
  left: -10px;
  font-size: 18px;
  color: #aa6326;
  margin-top: 15px;
  margin-bottom: 10px;
}
.m-time .sliders {
  padding: 0 10px;
}
.m-time .time {
  width: 65px;
  height: 65px;
  display: inline-block;
  font-size: 38px;
  line-height: 65px;
  background-color: #aa6326;
  border-radius: 3px;
  text-align: center;
}
.u-slider-time {
  position: relative;
  display: inline-block;
  background-color: #dfe0e4;
  border-radius: 3px;
  height: 10px;
  width: 100%;
  cursor: pointer;
}
.u-slider-time .value {
  position: absolute;
  background-color: #aa6326;
  border-radius: 3px;
  top: 0;
  height: 100%;
}
.u-slider-time .handle {
  position: absolute;
  width: 10px;
  height: 10px;
}
.u-slider-time .handle:after {
  position: relative;
  display: block;
  content: '';
  top: -10px;
  left: -15px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 3px solid #aa6326;
  border-radius: 50%;
  cursor: pointer;
}
.im-btn {
  display: inline-block;
  background-color: #eaebe9;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;
}
.im-btn:before {
  margin-right: 6px;
}
.m-input-moment {
  display: inline-block;
  width: 370px;
  padding: 12px 15px;
  border-radius: 3px;
  border: 1px solid #dfe0e4;
}
.m-input-moment .options {
  width: 100%;
  display: inline-block;
  margin-bottom: 4px;
}
.m-input-moment .options button {
  float: left;
  width: 50%;
  color: #aa6326;
  text-align: center;
  font-size: 20px;
  padding: 7px;
  border: 1px solid #aa6326;
  border-radius: 3px;
}
.m-input-moment .options button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.m-input-moment .options button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.m-input-moment .options button.is-active {
  color: #ffffff;
  background-color: #aa6326;
}
.m-input-moment .tab {
  display: none;
  height: 310px;
}
.m-input-moment .tab.is-active {
  display: block;
}
.m-input-moment .tabs {
  margin-bottom: 11px;
}
.m-input-moment .btn-save {
  display: block;
  margin-top: 10px;
  width: 100%;
  background-color: #aa6326;
  padding: 12px 0;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  border-radius: 3px;
}
